import { useState, useEffect } from "react";

const App = () => {
  const [value, setValue] = useState(null)
  const [message, setMessage] = useState(null)
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(message.content);
      console.log('Copied to clipboard:', message.content);
      setCopied(true);
    } catch (error) {
      console.error('Unable to copy to clipboard:', error);
    }
  };
  const getMessages = async () =>  {
    if(value === ''){
      alert('You must write something');
    }
    else {
      setCopied(false);
      const message = `Hi, I have to post on instagram, pls suggest me innovative catchy caption of 40 plus words(no. of words doesnt include hashtags), write only the caption in the response, with keywords: ${value}`

      try {
        const API_KEY = "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"
        const options = {
          method: 'POST',
          headers:{
              "Authorization":`Bearer ${API_KEY}` ,
              "Content-Type":'application/json'
  
          },
          body: JSON.stringify({
              model : "gpt-3.5-turbo",
              messages : [{role: "user", content: message}],
              max_tokens: 100,
          })
  
      }
  
      try {
          const response =  await fetch('https://api.openai.com/v1/chat/completions', options)
          const data = await response.json()
          setMessage(data.choices[0].message)
          
      } catch (error){
          console.error(error)
      }
      
        
      
      } catch (error) {
        console.error(error);
      }
    }
   
  }
  console.log(message)

  return (
    <div className="app">
      <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <section className="main">
        <h1>TheInstaCaption</h1>
        <p>✨🌍
          Let AI tell your travel tales! Our AI- ✈📖 <br/>
          generated travel storytelling feature crafts<br/>
          personalized narratives from your photos & experiences. <br/>Share unique stories 📸🤖
          capturing your adventures<br/>and memories with ease!
          Enhance engagement & social sharing effortlessly. #TravelStories #AIStorytelling</p>
        <div className="bottom-section">
          <div className="input-container">
            <input value={value} placeholder="Enter keywords like sunglasses, summer, maldives" onChange={(e) => setValue(e.target.value)}/>
            <button id="submit" onClick={getMessages}>Get Caption</button>
          </div>
          <div>{message && <div className="output-container">
            <div className="caption" id="myText">{message && <div>{message.content}</div>}</div>
            <div className="copy-button">{message && <button onClick={handleCopy}>{copied ? 'Copied!' : 'Copy'}</button>}</div>
          </div>}
          </div>
        </div>

      </section>
      
      <footer>
            <p>Made with ❤️ by Vani </p>
            <p>Contact: cs1230126@iitd.ac.in</p>

        </footer>

    </div>
    
    
    
  );
}

export default App;
